<!--促销计划-->
<template>
    <div class="Plan">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="120px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="促销机构">
                            <el-select v-model="form.deptCode" filterable style="width: 180px">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                    style="width: 180px"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="请选择" v-model="form.reviewStatus" style="width: 180px">
                                <el-option
                                    v-for="e in meta.reviewEnums"
                                    :label="e.label"
                                    :value="e.code"
                                    :key="e.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.enableFlag" style="width: 180px">
                                <el-option label="请选择" value="" />
                                <el-option label="启用" :value="true" />
                                <el-option label="停用" :value="false" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期-开始">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期-结束">
                            <el-date-picker
                                type="date"
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.promotion.plan.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.promotion.plan.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.promotion.plan.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.promotion.plan.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" base-url="/promotion" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" prop="code" width="170" v-if="showColumn('code')" />
                <el-table-column label="机构名称" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="方案名称" width="160" prop="name" v-if="showColumn('name')" />
                <el-table-column
                    label="促销类型"
                    width="100"
                    prop="ruleType"
                    v-if="showColumn('ruleType')"
                    key="ruleType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.ruleType | ruleType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="100"
                    prop="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    key="reviewStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="100"
                    prop="enableFlag"
                    v-if="showColumn('enableFlag')"
                    key="enableFlag"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.enableFlag ? '启用' : '停用' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="促销起始日期" width="100" prop="startDate" v-if="showColumn('startDate')" />
                <el-table-column label="促销结束日期" width="100" prop="endDate" v-if="showColumn('endDate')" />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="360"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.promotion.plan.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.reviewStatus != 0 || new Date(scope.row.startDate) <= new Date()"
                            v-if="hasPrivilege('menu.promotion.plan.edit')"
                            >编辑</el-button
                        >
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.reviewStatus != 0"
                            v-if="hasPrivilege('menu.promotion.plan.delete')"
                            >删除</el-button
                        >
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleOn(scope.row)"
                            :disabled="scope.row.reviewStatus != 2 || scope.row.enableFlag"
                            >启用</el-button
                        >
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleOff(scope.row)"
                            :disabled="scope.row.reviewStatus != 2 || !scope.row.enableFlag"
                            >停用</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfReviewBiz from 'components/EfReviewBiz';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'Plan',
    mixins: [CheckTableShowColumn],
    components: { EfReviewBiz, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                search: '',
                reviewStatus: '',
                enableFlag: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/promotion/page',
                delete: '/promotion/delete',
                review: '/promotion/review/',
            },
            meta: {
                depts: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.meta.depts = rst;
            this.form.deptCode = this.meta.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleOn(row) {
            const disable = row.reviewStatus == 2 && !row.enableFlag;
            if (!disable) {
                this.$message.error('该促销不能被启用,请选择已审核并且停用状态的促销进行启用');
            } else {
                const _params = {
                    code: row.code,
                    enableFlag: true,
                };
                UrlUtils.PatchRemote(this, '/promotion/enableOrDisable', _params, null, () => {
                    this.$confirm('操作成功');
                    this.handleQuery();
                });
            }
        },
        handleOff(row) {
            const disable = row.reviewStatus == 2 && row.enableFlag;
            if (!disable) {
                this.$message.error('该促销不能被停用,请选择已审核并且启用状态的促销进行停用');
            } else {
                const _params = {
                    code: row.code,
                    enableFlag: false,
                };
                UrlUtils.PatchRemote(this, '/promotion/enableOrDisable', _params, null, () => {
                    this.$confirm('操作成功');
                    this.handleQuery();
                });
            }
        },
        handleDelete(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.promotion.plan.create', ['促销管理', '促销方案', '新建促销方案']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.promotion.plan.detail', ['促销管理', '促销方案', '促销方案详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能编辑');
            } else {
                Util.nameJump(this, 'menu.promotion.plan.edit', ['促销管理', '促销方案', '促销方案编辑'], {
                    form: row,
                    code: row.code,
                });
            }
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '促销方案', '/promotion/export', this.form, codes);
        },
    },
    filters: {
        ruleType(type) {
            switch (type) {
                case 1: {
                    return '单品折扣';
                }
                case 2: {
                    return '单品偶数折扣';
                }
                case 3: {
                    return '单品特价';
                }
            }
        },
    },
};
</script>
